// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-projects-project-page-project-page-template-jsx": () => import("./../../../src/components/projects/project-page/project-page-template.jsx" /* webpackChunkName: "component---src-components-projects-project-page-project-page-template-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-resume-jsx": () => import("./../../../src/pages/resume.jsx" /* webpackChunkName: "component---src-pages-resume-jsx" */)
}

